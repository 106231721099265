/* === FONTS ===  */
@font-face {
	font-family: 'Quicksand';
	src: url('fonts/Quicksand-VariableFont_wght.ttf') format('truetype');
}

/* Tailwind.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Fix Tailwind base styles */
img,
video {
	max-width: none !important;
}

/* === GENERAL STYLES ===  */
* {
	box-sizing: border-box;
	overscroll-behavior: none;
}
body {
	margin: 0;
	padding: 0;
	width: 100vw;
	height: 100vh;
	/* touch-action: none; */
	position: fixed;

	font-family: 'Quicksand', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	--accent: #11547a;
	--accent-dark: #04446a;
	--accent-light: #1e6791;
	--background: #f3f3f3;
	--background-dark: #1d1d1d;
	--dark-darker: #1c1c1c;
	--dark-lighter: #333;
}

#root {
	width: 100%;
	height: 100%;
}

.app {
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 100dvh;
	overflow: hidden;
}

/* THEME STYLES */
main {
	flex-grow: 1;
	padding: 10px;
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow: hidden;
	box-sizing: border-box;
	position: relative;
	background-color: var(--background);
}
.dark main {
	background-color: var(--background-dark);
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
label {
	color: black;
}
.dark h1,
.dark h2,
.dark h3,
.dark h4,
.dark h5,
.dark h6,
.dark p,
.dark label {
	color: white;
}
.loader {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: var(--background);
	z-index: 9999;
}

label {
	margin-right: 10px;
}

input,
select,
button {
	border: 1px solid #ccc;
	padding: 3px 5px;
	outline: none;
	box-sizing: border-box;
	background-color: white;
}
button:hover {
	background-color: #f3f3f3;
}
input[type='text']:focus,
select:focus {
	border-color: #aaa;
}

.error {
	color: red;
}

.link {
	text-decoration: underline;
	/* font-weight: bold; */
	color: black;
	cursor: pointer;
}

.link:hover {
	color: #555;
}

.disabled {
	opacity: 0.6;
	pointer-events: none;
}

/* === NAVBAR === */
nav {
	width: auto;
	height: 100%;
	padding: 5px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	background-color: var(--accent-dark);
	/* overflow-y: scroll;
  overflow-x: hidden; */
}

nav a .title {
	position: absolute;
	top: 0;
	pointer-events: none;
	left: 50%;
	color: white;
	flex-direction: column;
	justify-content: center;
	z-index: 10000;
	padding: 5px;
	padding-right: 30px;
	font-size: 20px;
	height: 100%;
	box-sizing: border-box;
	background-color: var(--accent-light);
	transition: 0.2s all;
	display: flex;
	border-radius: 0 35px 35px 0;
	opacity: 0;
}
nav a:not(.active):hover .title {
	left: 100%;
	opacity: 1;
	pointer-events: all;
}

nav a:hover {
	border-radius: 50% 0 0 50%;
	color: #ddd;
	background-color: var(--accent-light);
}

nav a.active {
	background-color: var(--background);
	color: var(--accent-dark);
	border-radius: 50% 0 0 50%;
	position: relative;
}
nav a.active:hover {
	background-color: var(--background);
	color: var(--accent-dark);
}

nav a.active:after {
	content: '';
	position: absolute;
	top: 0;
	right: -5px;
	width: 20px;
	height: 100%;
	background-color: var(--background);
}
.dark nav a.active {
	color: var(--accent-light);
}
.dark nav a.active,
.dark nav a.active:hover,
.dark nav a.active:after {
	background-color: var(--background-dark);
}
nav a {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	border-radius: 50%;
	font-size: 30px;
	width: 70px;
	height: 70px;
	background-color: #11547a;
	color: white;
	box-sizing: border-box;
	position: relative;
}

/* === STYLED TABLE === */
.styled_table {
	width: 100%;
	height: 100%;
	font-size: 18px;
	position: relative;
	overflow: auto;
	border-radius: 10px 10px 0 0;
	border-bottom: 3px solid var(--accent);
}

.styled_table table {
	width: 100%;
	border-collapse: collapse;
}
.styled_table tbody tr {
	width: 100%;
	height: auto;
	border-bottom: 1px solid #ccc;
	background-color: white;
}

.styled_table tbody tr td {
	padding: 20px 10px;
	height: 100%;
}
.styled_table table tbody tr:not(:hover):nth-child(even) {
	background-color: #f7f7f7;
}
.styled_table tbody tr:hover {
	background-color: #e0e0e0;
}

.styled_table thead {
	font-weight: bold;
	position: sticky;
	top: 0;
	color: white;
	background-color: var(--accent);
}
.styled_table thead tr td {
	padding: 15px 10px;
}

.sortable_column {
	cursor: pointer;
	user-select: none;
	padding: 5px 10px;
}

.sortable_column:hover {
	color: #ccc;
}
.sortable_column .sort_hidden {
	opacity: 0;
}
.sortable_column:hover .sort_hidden {
	opacity: 1;
}

.dark .styled_table tbody tr {
	background-color: #555;
	border-bottom-color: #666;
	color: whitesmoke;
}
.dark .styled_table table tbody tr:not(:hover):nth-child(even) {
	background-color: #444;
}
.dark .styled_table tbody tr:hover {
	background-color: #333;
}

/* === METERS === */
.meter_wrap {
	width: fit-content;
	height: fit-content;
	height: min-content;
	position: relative;
	padding: 0;
	margin: 0;
	overflow: hidden;
	text-align: center;
}
.meter_wrap svg {
	z-index: 2;
	position: relative;
	display: block;
}

.thermometer_value {
	width: 100%;
	max-height: calc(100% - 10px);
	position: absolute;
	bottom: 5px;
	left: 0;
	z-index: 1;
}

.thermometer_detail_fill {
	position: absolute;
	top: 5px;
	right: 0;
	z-index: 0;
	width: 15px;
	height: 60px;
}

.hygrometer_value {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	transform: rotate(-180deg);
	transform-origin: 50% 100%;
	z-index: 1;
}
.hygrometer_value * {
	position: absolute;
	bottom: 0;
}
.hygrometer_value .hygro1 {
	width: 100%;
	height: 100%;
	background-color: black;
	border-radius: 50px 50px 0 0;
	left: 0;
	bottom: 0;
}
.hygrometer_value .hygro2 {
	width: 50px;
	height: 25px;
	background-color: white;
	border-radius: 25px 25px 0 0;
	left: 25px;
	bottom: -0.5px;
}

.hygrometer_detail_fill {
	position: absolute;
	top: 0;
	right: 0;
	width: calc(100% - 0.2px);
	height: 100%;
	z-index: 0;
}

/* === MISCELANEOUS === */
.floating_buttons {
	position: absolute;
	bottom: 20px;
	right: 20px;
	z-index: 100;
	display: flex;
	flex-direction: column;
	gap: 8px;
}
.floating_buttons button {
	padding: 0;
	margin: 0;
	width: 60px;
	height: 60px;
	background-color: var(--accent);
	transition: 0.2s background;
	outline: none;
	border: none;
	font-size: 40px;
	color: white;
	cursor: pointer;
	border-radius: 50%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.floating_buttons button:hover {
	background-color: var(--accent-dark);
}
.map_button {
	@apply outline-none border-none px-6 box-border rounded-none cursor-pointer hover:bg-gray-200;
}

.map_button.active {
	@apply hover:bg-accent-normal bg-accent-normal text-white;
}

/* .map_button.active::after {
  content: '';
  display: block;
  z-index: 100;
  width: inherit;
  height: 4px;
  position: absolute;
  bottom: -2px;
  left: 0;
  background-color: white;
} */

.group_data_matrix td {
	@apply p-3 border-gray-300 text-center text-lg;
}

.group_data_matrix thead tr {
	@apply border-b border-gray-300;
}

.group_data_matrix tr td:not(:last-child) {
	@apply border-r;
}

.group_data_matrix tr:not(:last-child) {
	@apply border-b border-gray-300;
}

.flow_card:not(.tail_hidden)::before {
	content: '';
	position: absolute;
	top: -1.1rem;
	left: 50%;
	height: 1.1rem;
	width: 2px;
	background-color: black;
	z-index: -100;
}
.dark .flow_card:not(.tail_hidden)::before {
	background-color: white;
}

.map_options_tray {
	transition: 0.1s ease-in all;
	display: flex;
	gap: 3px;
	z-index: 100;
	/* margin-right: 0; */
}
.map_options_tray.hidden {
	display: none;
	/* margin-right: -1000px; */
}

/* Responsive design */
@media screen and (max-width: 800px) {
	nav a {
		font-size: 20px;
		width: 50px;
		height: 50px;
	}
	.floating_buttons button {
		width: 45px;
		height: 45px;
		font-size: 30px;
	}

	.map_options_tray {
		position: absolute;
		flex-direction: column;
		top: calc(100% + 5px);
		right: 0;
		background-color: white;
		padding: 10px;
		border: 1px solid gray;
	}
}
